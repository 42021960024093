'use strict';

angular.module('windmanagerApp')
  .directive('listBlade', function() {
    return {
      templateUrl: 'components/directives/blade/listBlade/listBlade.html',
      restrict: 'EA',
      scope: {
        turbine: '=',
        bladeCount: '='
        // blades: '='
      },
      controller: 'ListBladeCtrl',
      controllerAs: 'listBlade',
      link: function(scope, element, attrs) {
      }
    };
  });
